import React, { useState, useEffect } from "react";
import st from "./Navbar.module.scss";
import { ReactComponent as Logo } from "assets/svg/headerMysoLogoWhite.svg";
import { ReactComponent as LogoColored } from "assets/svg/headerMysoLogoColored.svg";
import { ReactComponent as Icon } from "assets/svg/logInIconWhite.svg";
import { ReactComponent as IconBlack } from "assets/svg/logInIconBlack.svg";
import { Link } from "react-scroll";

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const isBottom =
        Math.ceil(window.innerHeight + window.scrollY) >= documentHeight;

      setIsAtBottom(isBottom);

      if (scrollY >= viewportHeight && !isBottom) {
        setScrolled(true);
      } else if (scrollY < viewportHeight) {
        setScrolled(false);
        setActiveSection("");
      }

      if (isBottom) {
        setActiveSection("support");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSetActive = (to: string) => {
    if (!isAtBottom || to !== "support") {
      setActiveSection(to);
    }
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setActiveSection("");
  };

  const handleSupportClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    setActiveSection("support");
  };

  return (
    <div className={`${st.container} ${scrolled ? st.scrolled : ""}`}>
      <div className={st.logo_container} onClick={handleLogoClick}>
        <Logo className={`${st.logo} ${scrolled ? st.hidden : st.visible}`} />
        <LogoColored
          className={`${st.logo_colored} ${scrolled ? st.visible : st.hidden}`}
        />
      </div>
      <div className={st.button_container}>
        <Link
          to="system"
          spy={true}
          smooth={true}
          duration={1200}
          className={`${st.transparent_button} ${
            activeSection === "system" ? st.active : ""
          }`}
          onSetActive={() => handleSetActive("system")}
        >
          시스템소개
        </Link>
        <Link
          to="managementSolution"
          spy={true}
          smooth={true}
          duration={1200}
          className={`${st.transparent_button} ${
            activeSection === "managementSolution" ? st.active : ""
          }`}
          onSetActive={() => handleSetActive("managementSolution")}
        >
          서비스
        </Link>
        <Link
          to="news"
          spy={true}
          smooth={true}
          duration={1200}
          className={`${st.transparent_button} ${
            activeSection === "news" ? st.active : ""
          }`}
          onSetActive={() => handleSetActive("news")}
        >
          NEWS
        </Link>
        <button
          className={`${st.transparent_button} ${
            activeSection === "support" ? st.active : ""
          }`}
          onClick={handleSupportClick}
        >
          지원센터
        </button>
      </div>
      <div className={st.login_container}>
        {scrolled ? (
          <IconBlack className={st.icon} />
        ) : (
          <Icon className={st.icon} />
        )}
        <button className={st.login_button}>로그인</button>
      </div>
    </div>
  );
};

export default Navbar;
