import React, { useState } from "react";
import st from "./ManagementSolution.module.scss";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ourCoreValuesBackground from "assets/image/ourCoreValuesBackground.png";
import computerBlockchian from "assets/image/computerBlockchian.png";
import computerCopyrightTrading from "assets/image/computerCopyrightTrading.png";
import computerDispersedSaving from "assets/image/computerDispersedSaving.png";
import computerSmartLicense from "assets/image/computerSmartLicense.png";
import computerTracking from "assets/image/computerTracking.png";
import { ReactComponent as NextArrowIcon } from "assets/svg/nextArrow.svg";
import { ReactComponent as PrevArrowIcon } from "assets/svg/prevArrow.svg";
import "./ManagementSolution.scss";

interface ArrowProps {
  onClick?: () => void;
}

const NextArrow = (props: ArrowProps) => {
  const { onClick } = props;
  return <NextArrowIcon className={st.next_arrow} onClick={onClick} />;
};

const PrevArrow = (props: ArrowProps) => {
  const { onClick } = props;

  return <PrevArrowIcon className={st.prev_arrow} onClick={onClick} />;
};

const ManagementSolution = () => {
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings: Settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  const dotClickHandler = (index: number) => {
    if (sliderRef) {
      sliderRef.slickGoTo(index);
    }
  };

  return (
    <div className={st.management_solution}>
      <div className={st.our_core_values}>
        <div className={st.background_overlay}></div>
        <div className={st.text_container}>
          <h4>OUR CORE VALUES</h4>
          <h3>
            소프트웨어 저작권
            <br />
            관리 솔루션 제공
          </h3>
          <p>
            myso는 블록체인 기반 소프트웨어 지적재산권(IP) 유통 및 관리
            플랫폼입니다.
            <br />
            소프트웨어 지적재산권(IP) 유통 및 관리 플랫폼입니다.
            <br />
            설명 텍스트가 들어갈 텍스트 영역입니다.
          </p>
        </div>
        <div className={st.buttons_container}>
          <div className={st.buttons}>
            <ul>
              <li
                className={currentSlide === 0 ? st.slick_active : ""}
                onClick={() => dotClickHandler(0)}
              >
                <button>저작물의 블록체인 등록</button>
              </li>
              <li
                className={currentSlide === 1 ? st.slick_active : ""}
                onClick={() => dotClickHandler(1)}
              >
                <button>스마트 라이선스 관리</button>
              </li>
              <li
                className={currentSlide === 2 ? st.slick_active : ""}
                onClick={() => dotClickHandler(2)}
              >
                <button>분산 저장 및 접근 제어</button>
              </li>
              <li
                className={currentSlide === 3 ? st.slick_active : ""}
                onClick={() => dotClickHandler(3)}
              >
                <button>저작물의 사용 추적 및 감시</button>
              </li>
              <li
                className={currentSlide === 4 ? st.slick_active : ""}
                onClick={() => dotClickHandler(4)}
              >
                <button>분산화된 판권 거래 시스템</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={st.slider_container}>
        <div className={st.mobile_background}></div>
        <Slider
          {...settings}
          ref={(slider) => setSliderRef(slider)}
          className={st.slide_style}
        >
          <div className={st.column_style}>
            <h3>저작물의 블록체인 등록</h3>
            <span>저작물의 블록체인 등록 화면 설명입니다.</span>
            <img src={computerBlockchian} />
          </div>
          <div>
            <h3>스마트 라이선스 관리</h3>
            <span>스마트 라이선스 관리 화면 설명입니다.</span>
            <img src={computerSmartLicense} />
          </div>
          <div>
            <h3>분산 저장 및 접근 제어</h3>
            <span>분산 저장 및 접근 제어 화면 설명입니다.</span>
            <img src={computerDispersedSaving} />
          </div>
          <div>
            <h3>저작물의 사용 추적 및 감시</h3>
            <span>저작물의 사용 추적 및 감시 화면 설명입니다.</span>
            <img src={computerTracking} />
          </div>
          <div>
            <h3>분산화된 판권 거래 시스템</h3>
            <span>분산화된 판권 거래 시스템화면 설명입니다.</span>
            <img src={computerCopyrightTrading} />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default ManagementSolution;
