import { ReactComponent as DownArrow } from "assets/svg/downArrow.svg";
import { ReactComponent as UpArrow } from "assets/svg/upArrow.svg";
import st from "./Question.module.scss";

interface Props {
  index: number;
  question: string;
  handleClick: (index: number) => void;
  isActive: number | undefined;
}

const Question = ({ index, question, handleClick, isActive }: Props) => {
  return (
    <div
      className={`${st.question_container} ${
        isActive === index ? st.active : ""
      }`}
      onClick={() => handleClick(index)}
    >
      <div className={st.question}>
        <span>Q.</span>
        <p>{question}</p>
      </div>
      {isActive === index ? <UpArrow /> : <DownArrow />}
    </div>
  );
};

export default Question;
