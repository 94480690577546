import st from "./SectionTitle.module.scss";

interface Props {
  mainTitle: string;
  subTitle: string;
  detailInfo?: string;
}

const SectionTitle = ({ mainTitle, subTitle, detailInfo }: Props) => {
  return (
    <section className={st.section_title_container}>
      <span className={st.sub_title}>{subTitle}</span>
      <h4 className={st.main_title}>{mainTitle}</h4>
      {detailInfo && <p className={st.detail_info}>{detailInfo}</p>}
    </section>
  );
};

export default SectionTitle;
