import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import Dashboard from "pages/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: "Not Found",
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
