import { ReactComponent as DoubleArrow } from "assets/svg/doubleArrow.svg";

import st from "./Support.module.scss";

const Support = () => {
  return (
    <div className={st.support_container}>
      <p className={st.title}>리소스 및 지원</p>
      <p className={st.sub_title}>myso에 대해서 더 궁금한 점이 있으신가요?</p>
      <div className={st.buttons_container}>
        <button>
          지원센터
          <span>
            <DoubleArrow />
          </span>
        </button>
        <button className={st.inquiry}>
          문의하기
          <span>
            <DoubleArrow />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Support;
