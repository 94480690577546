import news1 from "assets/image/news1.png";
import news2 from "assets/image/news2.png";
import news3 from "assets/image/news3.png";
import news4 from "assets/image/news4.png";
import ButtonMore from "components/ButtonMore";
import SectionTitle from "components/SectionTitle";
import Card from "./Card";

import st from "./News.module.scss";

const News = () => {
  return (
    <div className={st.news_container}>
      <SectionTitle
        subTitle="news"
        mainTitle="지금 우리는"
        detailInfo="myso의 새로운 소식을 전달드립니다."
      />
      <div className={st.card_container}>
        <Card
          image={news1}
          category="NEWS"
          title="AI 기술로 빠른 검색 기능 추가: 사용자 경험 혁신"
          date="2024.07.01"
        />
        <Card
          image={news2}
          category="NEWS"
          title="AI 기술로 빠른 검색 기능 추가: 사용자 경험 혁신"
          date="2024.07.01"
        />
        <Card
          image={news3}
          category="NEWS"
          title="AI 기술로 빠른 검색 기능 추가: 사용자 경험 혁신"
          date="2024.07.01"
        />
        <Card
          image={news4}
          category="NEWS"
          title="AI 기술로 빠른 검색 기능 추가: 사용자 경험 혁신"
          date="2024.07.01"
        />
      </div>
      <ButtonMore />
    </div>
  );
};

export default News;
